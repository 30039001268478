@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "NotoSansKR-Medium.ttf";
  font-weight: normal;
  src: url("./font/NotoSansKR-Medium.ttf") format("truetype");
}

body {
  font-family: "NotoSansKR-Medium.ttf";
}

html,
#root {
  width: 100vw;
  height: 100vw;
  @apply bg-primary-light;
}
